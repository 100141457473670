/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import * as Linking from 'expo-linking';
import { PUBLIC_URL } from '../constants/Urls';

export default {
	prefixes: [Linking.makeUrl('/')],
	config: {
		screens: {
			Root: {
				screens: {
					Reservations: {
						screens: {
							ReservationsScreen: PUBLIC_URL + '/reservations',
						},
					},
					Orders: {
						screens: {
							OrdersScreen: PUBLIC_URL + '/orders',
						},
					},
				},
			},
			Auth: PUBLIC_URL + '/auth',
			NotFound: PUBLIC_URL + '/*',
		},
	},
};
