import React, { useState, useEffect } from 'react';
import { View, Button, Text, StyleSheet, Alert, Switch, Pressable } from 'react-native';
import { API_URL } from '../constants/Urls';
import useAuthNotifContext from '../hooks/useAuthNotifContext';


export default function NotFoundScreen() {
	const { disconnect } = useAuthNotifContext();

	return (
		<View style={styles.container}>
			<Button title="Déconnection" onPress={() => Alert.alert(
				"Déconnexion",
				"Êtes vous sûr de vouloir vous déconnecter ?",
				[
					{ text: "Annuler", style: "cancel" },
					{ text: "Valider", onPress: () => disconnect() }
				]
			)} />
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: 'space-between',
		backgroundColor: '#fff',
		padding: 20,
	},
	title: {
		fontSize: 20,
		fontWeight: 'bold',
	},
	link: {
		marginTop: 15,
		paddingVertical: 15,
	},
	linkText: {
		fontSize: 14,
		color: '#2e78b7',
	},
	switchContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: 10
	},
	switchLabel: {
		fontSize: 18,
		fontWeight: 'bold'
	}
});
