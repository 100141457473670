import React from 'react';
import { View, Text, StyleProp, TextStyle } from 'react-native';
import { FontAwesome5 } from '@expo/vector-icons';

export default function IconText({ icon, children, style }: {icon: string, children: string | string[], style?	: StyleProp<TextStyle>}) {
	return (
		<View style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
			<FontAwesome5 name={icon} style={[{ marginRight: 5 }, style]} />
			<Text style={style}>{children}</Text>
		</View>
	);
}