import React, { useState, useCallback, useEffect, useRef } from 'react';
import { StyleSheet, FlatList, Pressable, Modal, Alert, Switch, View, Platform } from 'react-native';

import { Subscription } from "@unimodules/core";
import { addNotificationReceivedListener } from 'expo-notifications';

import { Text } from '../components/Themed';
import { API_URL } from '../constants/Urls';

import CalendarPicker, { mois, jours } from '../components/CalendarPicker';
import { FontAwesome5 } from '@expo/vector-icons';
import IconText from '../components/IconText';
import useAuthNotifContext from '../hooks/useAuthNotifContext';
import Colors from '../constants/Colors';
import netErrMsg from "../constants/NetworkErrorMsg";
import RefreshButton from '../components/RefreshButton';

type OrderProduct = {
	id: number,
	name: string,
	price: number,
	quantity?: string,
	number: number
}

type OrderListItemProps = {
	id: number,
	name: string,
	firstname: string,
	email: string,
	telephone: string,
	time: string,
	accepted: boolean,
	paid: boolean,
	products: OrderProduct[],
	onAcceptedChange: (value: boolean) => void,
}

function DateButton({ date, onDate }: { date: Date, onDate: (event: string) => void }) {
	const [modalVisible, setModalVisible] = useState(false);

	return (
		<Pressable
			style={({ pressed }) => ({
				alignSelf: 'center',
				margin: 5,
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				padding: 5,
				borderRadius: 5,
				backgroundColor: pressed ? Colors.infodark : Colors.info,
				shadowColor: 'black',
				shadowOpacity: 0.25,
				shadowRadius: 5,
				elevation: pressed ? 1 : 3,
			})}
			onPress={() => setModalVisible(visible => !visible)}
		>
			<FontAwesome5 name="calendar-day" style={{ marginRight: 3, color: 'white' }} />
			<Text style={{ color: 'white' }}>{jours[date.getDay()]} {date.getDate()} {mois[date.getMonth()]} {date.getFullYear()}</Text>
			<Modal
				animationType="slide"
				transparent={true}
				visible={modalVisible}
				onRequestClose={() => {
					Alert.alert("Modal has been closed.");
					setModalVisible(!modalVisible);
				}}
			>
				<View style={styles.centeredView}>
					<View style={styles.modalView}>
						<Pressable
							style={({ pressed }) => ({
								alignSelf: 'flex-end',
								width: 30, height: 30,
								padding: 5,
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								borderRadius: 15,
								backgroundColor: pressed ? Colors.infodark : Colors.info,
								shadowColor: 'black',
								shadowOpacity: 0.25,
								shadowRadius: 5,
								elevation: pressed ? 1 : 3,
							})}
							onPress={() => setModalVisible(visible => !visible)}
						>
							<FontAwesome5 name="times" style={{ color: 'white' }} />
						</Pressable>
						<CalendarPicker onDatePicked={(date) => { onDate(date); setModalVisible(visible => !visible) }} />
					</View>
				</View>
			</Modal>
		</Pressable>
	);
}

function OrderListItem({ item }: { item: OrderListItemProps }) {
	const [extended, setExtended] = useState(false);

	if (item.products.length <= 0)
		return null;

	return (
		<Pressable
			style={({ pressed }) => [styles.orderContainer, { elevation: pressed ? 2 : 4 }]}
			onPress={() => setExtended(e => !e)}
		>
			{
				item.accepted &&
				<View pointerEvents="none" style={[styles.absoluteBackground, { backgroundColor: Colors.success }]}>
					<FontAwesome5 name='check'
						style={[styles.absoluteBackground, { opacity: 0.1, fontSize: 80 }]}
					/>
				</View>
				||
				!item.paid &&
				<View pointerEvents="none" style={[styles.absoluteBackground, { backgroundColor: Colors.danger }]}>
					<FontAwesome5 name='ban'
						style={[styles.absoluteBackground, { opacity: 0.1, fontSize: 80 }]}
					/>
					<FontAwesome5 name='euro-sign'
						style={[styles.absoluteBackground, { left: -5, right: 5, opacity: 0.1, fontSize: 50 }]}
					/>
				</View>
			}
			<View style={styles.flexRowView}>
				<IconText icon="shopping-bag" style={{ fontWeight: 'bold' }}>
					{item.products.length.toString(10)}
				</IconText>
				<IconText icon="clock">
					{item.time.split(':').slice(0, 2).reduce((a, b) => a + 'h' + b)}
				</IconText>
			</View>
			<IconText icon="tag">
				{item.name} {item.firstname}
			</IconText>
			<View style={styles.flexRowView}>
				<IconText icon="envelope">
					{item.email}
				</IconText>
				<IconText icon="phone">
					{item.telephone}
				</IconText>
			</View>
			<View style={{ flex: 1, height: 1, marginHorizontal: -5, marginVertical: 5, backgroundColor: Colors.infodark }} />
			{
				extended &&
				<View style={{ padding: 5 }}>
					{
						item.products.map(product =>
							<View key={product.id} style={styles.flexRowView}>
								<Text>
									<Text style={{ fontWeight: "bold" }}>{product.number}</Text>
									{" x "}
									{product.name}
									{product.quantity ? ' (' + product.quantity + ')' : ''}
								</Text>
								<Text>{(product.price * product.number).toFixed(2)}€</Text>
							</View>
						)
					}
					<View style={{ flex: 1, height: 1, margin: 5, backgroundColor: 'black' }} />
					<View style={styles.flexRowView}>
						<Text style={{ fontWeight: "bold" }}>Total</Text>
						<Text style={{ fontWeight: "bold" }}>{item.products.reduce((a, c) => (a + c.price * c.number), 0).toFixed(2)}€</Text>
					</View>
					<View style={[styles.flexRowView, { alignItems: 'flex-end' }]}>
						<Text>{item.accepted ? "Prête !" : "En attente..."}</Text>
						<Switch
							trackColor={{ false: Colors.info, true: Colors.success }}
							thumbColor='#fff'
							ios_backgroundColor="#3e3e3e"
							onValueChange={item.onAcceptedChange}
							value={item.accepted}
						/>
					</View>
				</View>
			}
			<View style={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginVertical: 5 }}>
				<FontAwesome5 name={extended ? "angle-up" : "angle-down"} color={Colors.infodark} style={{ fontSize: 20 }} />
			</View>
		</Pressable>
	);
}

export default function OrdersScreen() {
	const { authToken, onNotification } = useAuthNotifContext();

	const [date, setDate] = useState(new Date());
	const [orders, setOrders] = useState<OrderListItemProps[]>();
	const [refreshing, setRefreshing] = useState(false);
	const [emptyMsg, setEmptyMsg] = useState("");

	const notificationListener = useRef<Subscription>();

	const fetchOrders = useCallback(() => {
		setRefreshing(true);
		setOrders([]);
		setEmptyMsg("Chargement...");

		fetch(API_URL + `/orders?date=${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
			{
				method: 'GET',
				headers: {
					Authorization: `bearer ${authToken}`
				}
			}
		)
		.then(res => {
			if (res.ok)
				return res.json();
			else
				throw new Error(`${res.status}`);
		})
		.then(data => {
			if (data && data.length > 0) {
				setOrders(data.map((d: OrderListItemProps, i: number) => {
					d.accepted = !!d.accepted; // cast to boolean
					d.paid = !!d.paid;
					d.onAcceptedChange = (val) => updateOrders(val, i);
					return d;
				}));
			}
			else {
				setEmptyMsg("Aucune commande à cette date.")
			}
			setRefreshing(false);
		})
		.catch(error => {
			console.error('Error fetching orders:', error);
			setEmptyMsg(netErrMsg);
			setRefreshing(false);
		})
	}, [date, setRefreshing]);

	const updateOrders = useCallback((accepted: boolean, id: number) => {
		setRefreshing(true);
		setOrders(orders => {
			if (orders) {
				const prev = orders[id].accepted;

				fetch(API_URL + `/orders/${orders[id].id}`,
					{
						method: 'PUT',
						headers: {
							Authorization: `bearer ${authToken}`,
							'content-Type': 'application/json',
						},
						body: JSON.stringify({ accepted: accepted }),
					}
				)
				.then(res => {
					if (res.ok) {
						res.json().then(order => {
							orders[id].accepted = order.accepted;
							setOrders([...orders]);
							setRefreshing(false);
						})
					}
					else
						throw new Error(`${res.status}`);
				})
				.catch(error => {
					console.error('Error fetching orders:', error);
					orders[id].accepted = prev;
					setOrders([...orders]);
					setEmptyMsg(netErrMsg);
					setRefreshing(false);
				})

				orders[id].accepted = accepted;
				setOrders([...orders]);
				return orders;
			}
		});

	}, [setOrders]);

	useEffect(() => {
		fetchOrders();
	}, [date]);

	useEffect(() => {
		// This listener is fired whenever a notification is received while the app is foregrounded
		notificationListener.current = addNotificationReceivedListener(notification => {
			if (notification.request.content.data.type === 'order') {
				fetchOrders();
			}
		});

		return () => {
			if (notificationListener.current)
				notificationListener.current.remove();
		}
	}, [])

	useEffect(() => {
		onNotification(() => {
			return () => fetchOrders();
		})
	}, [onNotification])

	return (
		<View style={styles.container}>
			{
				Platform.OS === 'web' ? <RefreshButton onPress={fetchOrders} /> : null
			}
			<FlatList
				data={orders}
				renderItem={({ item }) => <OrderListItem item={item} />}
				keyExtractor={item => item.id.toString(10)}
				onRefresh={fetchOrders}
				refreshing={refreshing}
				ListEmptyComponent={
					<Text style={styles.title}>{emptyMsg}</Text>
				}
				ListHeaderComponent={
					Platform.OS !== 'web' ?
						<DateButton date={date} onDate={(date) => setDate(new Date(date))} />
						:
						<input type='date' style={{ margin: '1em' }}
							defaultValue={date.toISOString().substring(0, 10)}
							onBlur={e => {
								try {
									let d = new Date(e.target.value);
									setDate(d);
								}
								catch (e) {
									console.error(e);
								}
							}}
						/>
				}
			/>
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		alignItems: 'stretch',
		backgroundColor: 'white',
		paddingTop: 10,
	},
	title: {
		fontSize: 20,
		alignSelf: 'center',
		fontWeight: 'bold',
		textAlign: 'center',
	},
	centeredView: {
		flex: 1,
		justifyContent: "center",
		alignItems: "stretch",
		backgroundColor: "transparent",
	},
	modalView: {
		flex: 1,
		margin: 20,
		padding: 20,
		borderRadius: 20,
		backgroundColor: "white",
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 2
		},
		elevation: 5
	},
	flexRowView: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	orderContainer: {
		alignSelf: 'stretch',
		position: 'relative',
		overflow: 'hidden',
		display: 'flex',
		marginHorizontal: 10,
		marginVertical: 5,
		padding: 10,
		borderRadius: 5,
		backgroundColor: 'white',
		shadowColor: 'black',
		shadowOpacity: 0.25,
		shadowRadius: 2,
		elevation: 5,
	},
	absoluteBackground: {
		position: 'absolute',
		top: 0, right: 0, bottom: 0, left: 0,
		opacity: 0.5,
		textAlign: 'center',
		textAlignVertical: 'center',
	}
});
