import React, { useState, useEffect } from 'react';
import { StyleSheet, Pressable, GestureResponderEvent } from 'react-native';
import { FontAwesome5 } from '@expo/vector-icons';

import { Text, View } from '../components/Themed';
import Colors from '../constants/Colors';


type PressableDateProps = {
	date: number,
	nbReservation: number,
	onPress: (event: GestureResponderEvent) => void,
	fromToday: number,
	isInMonth: boolean,
	isLastRow: boolean,
	isLastColumn: boolean
}

function IconButton({ icon, onPress }: { icon: string, onPress: (event: GestureResponderEvent) => void }) {
	return (
		<Pressable
			style={({ pressed }) => [
				styles.button,
				pressed && {
					elevation: 1,
				}
			]}
			onPress={onPress}
		>
			<FontAwesome5 name={icon} />
		</Pressable>
	);
}

function PressableDate({ date, nbReservation, onPress, fromToday, isInMonth, isLastRow, isLastColumn }: PressableDateProps) {
	return (
		<Pressable
			style={({ pressed }) => [{
				flex: 1,
				position: 'relative',
				paddingVertical: 10,
				borderTopWidth: 0,
				borderRightWidth: 1,
				borderBottomWidth: 1,
				borderLeftWidth: 0,
				borderStyle: 'solid',
				backgroundColor: fromToday === 0 ? Colors.info : "transparent",
				borderTopColor: 'transparent',
				borderRightColor: isLastColumn ? 'transparent' : Colors.infodark,
				borderBottomColor: isLastRow ? 'transparent' : Colors.infodark,
				borderLeftColor: 'transparent',
			},
			pressed && {
				backgroundColor: Colors.infolight,
			}
			]}
			onPress={onPress}
		>
			<Text style={{
				textAlign: 'center', fontSize: 18,
				fontWeight: fromToday === 0 ? "bold" : "normal",
				color: isInMonth ? (fromToday === 0 ? "white" : fromToday > 0 ? "black" : Colors.infodark) : Colors.infolight,
			}}>
				{date}
			</Text>
			{
				nbReservation > 0 &&
				<Text numberOfLines={1} ellipsizeMode="clip"
					style={[styles.dayCount, fromToday < 0 ? styles.dayCountPast : {}]}
				>
					{nbReservation > 99 ? "99+" : nbReservation}
				</Text>
			}
		</Pressable>
	);

}

export default function CalendarPicker({ nbNotifications, onDatePicked, onMonthChange }:
	{
		nbNotifications?: { [key: string]: number },
		onDatePicked?: (event: string) => void,
		onMonthChange?: (event: { year: number, month: number }) => void
	}
) {

	const [today] = useState(new Date());
	const [date, setDate] = useState({ year: today.getFullYear(), month: today.getMonth() });
	const [calendar, setCalendar] = useState(getCalendar(date.year, date.month));

	const changeMonth = (amount: number) => {
		setDate(date => {
			date.month += amount;
			if (date.month < 0) {
				date.year--;
				date.month += 12;
			}
			else if (date.month >= 12) {
				date.year++;
				date.month -= 12;
			}

			if (onMonthChange)
				onMonthChange(date);

			setCalendar(getCalendar(date.year, date.month));

			return date;
		})
	}

	return (
		<View style={styles.container}>
			<View style={styles.calendarHead}>
				<IconButton onPress={() => changeMonth(-1)} icon="angle-left" />
				<Text style={styles.title}>{mois[date.month]} {date.year}</Text>
				<IconButton onPress={() => changeMonth(1)} icon="angle-right" />
			</View>
			<View style={styles.separator} />
			<View style={styles.calendarRow}>
				{
					['L', 'M', 'M', 'J', 'V', 'S', 'D'].map((j, i) =>
						<Text key={i} style={{
							flex: 1,
							textAlign: 'center', fontWeight: 'bold',
							borderRightWidth: i < 6 ? 1 : 0,
							borderRightColor: Colors.infodark
						}}>{j}</Text>
					)
				}
			</View>
			<View style={styles.separator} />
			{
				calendar.map((week, rowId) =>
					<View key={rowId} style={styles.calendarRow}>
						{
							week.map((day, id) => {
								const dayString = day.toJSON().split('T')[0];
								const nbReservation = nbNotifications && dayString in nbNotifications ? nbNotifications[dayString] : 0;
								const dateCmp = compareDate(day, today);

								return (
									<PressableDate key={id}
										date={day.getDate()}
										nbReservation={nbReservation}
										onPress={onDatePicked ? () => onDatePicked(dayString) : () => { }}
										fromToday={dateCmp}
										isInMonth={day.getMonth() === date.month}
										isLastRow={rowId === calendar.length - 1}
										isLastColumn={id === 6}
									/>
								);
							})
						}
					</View>
				)
			}
		</View>
	);
}

function getCalendar(year: number, month: number) {
	let firstDate = new Date(year, month);
	let nbDay = new Date(year, month + 1, 0).getDate();

	let day = 1 - (firstDate.getDay() + 6) % 7;

	let calendar = [];
	while (day <= nbDay) {
		let week = [];
		for (let j = 0; j < 7; j++) {
			week.push(new Date(Date.UTC(year, month, day)));
			day++;
		}
		calendar.push(week);
	}

	return calendar;
}

function compareDate(d1: Date, d2: Date) {
	const date2int = (d: Date) => {
		return parseInt(d.toJSON().split('T')[0].split('-').reduce((a: string, b: string) => a + b))
	}
	return date2int(d1) - date2int(d2);
}

export const mois = [
	"Janvier",
	"Février",
	"Mars",
	"Avril",
	"Mai",
	"Juin",
	"Juillet",
	"Août",
	"Septembre",
	"Octobre",
	"Novembre",
	"Décembre"
]

export const jours = [
	"Dimanche",
	"Lundi",
	"Mardi",
	"Mercredi",
	"Jeudi",
	"Vendredi",
	"Samedi",
]

const styles = StyleSheet.create({
	container: {
		flex: 1,
		alignSelf: 'stretch',
		display: 'flex',
		backgroundColor: 'white'
	},
	button: {
		width: 36,
		height: 36,
		margin: 2,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 5,
		shadowColor: 'black',
		shadowOpacity: 0.25,
		shadowRadius: 5,
		elevation: 3,
		backgroundColor: 'white',
	},
	calendarHead: {
		display: 'flex',
		alignSelf: 'stretch',
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		marginTop: 20,
		marginHorizontal: 10
	},
	calendarRow: {
		display: 'flex',
		alignSelf: 'stretch',
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center"
	},
	title: {
		fontSize: 20,
		fontWeight: 'bold',
	},
	separator: {
		marginVertical: 10,
		height: 2,
		backgroundColor: Colors.infodark,
	},
	dayCount: {
		position: 'absolute',
		top: 0, right: 0,
		height: 16,
		minWidth: 16,
		paddingHorizontal: 3,
		borderRadius: 8,
		fontSize: 12,
		textAlign: 'center',
		textAlignVertical: 'center',
		color: 'white',
		backgroundColor: Colors.danger,
	},
	dayCountPast: {
		color: "black",
		backgroundColor: Colors.infolight,
	}
});
