import React, { useState, useEffect, useCallback } from 'react';
import { FlatList, StyleSheet,Platform } from 'react-native';
import { ReservationObject, ReservationsProps } from '../types';

import { Text, View } from '../components/Themed';

import { API_URL } from '../constants/Urls';
import IconText from '../components/IconText';
import RefreshButton from '../components/RefreshButton';
import useAuthNotifContext from '../hooks/useAuthNotifContext';
import Colors from '../constants/Colors';
import netErrMsg from '../constants/NetworkErrorMsg';

type ReservationListItemProps = {
	type: string,
	key: string,
	reservation?: ReservationObject,
	hour?: number
}

function HourSeparator({ hour }: { hour: number }) {
	return (
		<View style={styles.hourSeparator}>
			<View style={{flex: 1, height: 2, backgroundColor: Colors.infodark, marginHorizontal: 10}}></View>
			<Text style={{fontWeight: 'bold', color: Colors.infodark}}>{hour}h</Text>
			<View style={{flex: 1, height: 2, backgroundColor: Colors.infodark, marginHorizontal: 10}}></View>
		</View>
	);
}

function ReservationPreview({ reservation }: {reservation: ReservationObject}) {
	return (
		<View style={styles.reservationContainer}>
			<View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
				<IconText icon="user-friends" style={{fontWeight: 'bold'}}>
					{reservation.number.toString(10)}
				</IconText>
				<IconText icon="clock">
					{reservation.time.split(':').slice(0,2).reduce((a,b) => a + 'h' + b)}
				</IconText>
			</View>
			<IconText icon="tag">
				{reservation.name} {reservation.firstname}
			</IconText>
			<View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
				<IconText icon="envelope">
					{reservation.email}
				</IconText>
				<IconText icon="phone">
					{reservation.telephone}
				</IconText>
			</View>
		</View>
	)
}

function ReservationListItem({ item }: {item: ReservationListItemProps }) {
	const { type, reservation, hour } = item;
	switch (type) {
		case 'ReservationPreview':
			if (reservation)
				return <ReservationPreview reservation={reservation} />
			else
				return null;
		case 'HourSeparator':
			if (hour)
				return <HourSeparator hour={hour} />
			else
				return null;
		case 'Point':
			return <Text style={{textAlign: 'center', fontWeight: 'bold'}}>...</Text>
		default:
			return null;
	}
}

export default function ReservationsOfTheDayScreen({ route }: ReservationsProps) {
	const { authToken } = useAuthNotifContext();
	const { date } = route.params;
	const [ reservations, setReservations ] = useState<ReservationListItemProps[]>();
	const [ isLoading, setLoading] = useState(false);
	const [emptyMsg, setEmptyMsg] = useState("");

	const fetchReservations = useCallback(() => {
		setLoading(true);
		setEmptyMsg("Chargement...");
		setReservations([]);

		fetch(API_URL + `/reservations?
			date_eq=${date}&
			_sort=time:asc`,
			{
				method: 'GET',
				headers: {
					Authorization: `bearer ${authToken}`
				}
			}
		)
		.then(res => {
			if (res.ok)
				return res.json();
			else
				throw new Error('Netwotk error');
		})
		.then(data => {
			if (data && data.length > 0) {
				let time = parseInt(data[0].time.split(":")[0]);
				let timeline: ReservationListItemProps[]  = [{ type:'HourSeparator', key: `head-${time}`, hour: time }];
				
				for (let i = 0; i < data.length; i++) {
					let currentTime = parseInt(data[i].time.split(":")[0]);
					if (currentTime !== time) {
						timeline.push({ type: 'HourSeparator', key: `head-${i}`, hour: time+1 });
						if (time + 1 < currentTime) {
							timeline.push({ type: 'Point', key: `point-${i}` })
							timeline.push({ type: 'HourSeparator', key: `foot-${i}`, hour: currentTime });
						}
						
						time = currentTime;
					}
					timeline.push({ type: 'ReservationPreview', key: `res-${i}`, reservation: data[i] });
				}
				timeline.push({ type: 'HourSeparator', key: 'foot', hour: time+1 });

				setReservations(timeline);
			}
			else {
				setEmptyMsg("Aucune réservation à cette date.");
			}

			setLoading(false);
		})
		.catch(error => {
			console.error("Error fetching reservations:", error);
			setLoading(false);
			setEmptyMsg(netErrMsg);
		})
	}, [date])

	useEffect(() => {
		fetchReservations();
	}, [])

	return (
		<View style={styles.container}>
			{
				Platform.OS === 'web' ? <RefreshButton onPress={fetchReservations} /> : null
			}
			<FlatList
				data={reservations}
				renderItem={ReservationListItem}
				keyExtractor={item => item.key}
				onRefresh={fetchReservations}
				refreshing={isLoading}
				ListEmptyComponent={
					<Text style={styles.title}>{emptyMsg}</Text>
				}
			/>
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		alignItems: 'stretch',
		backgroundColor: 'white'
	},
	title: {
		marginTop: 20,
		fontSize: 20,
		fontWeight: 'bold',
		textAlign: 'center'
	},
	hourSeparator: {
		display: 'flex', 
		flexDirection: 'row', 
		alignItems: 'center', 
		justifyContent: 'center', 
		backgroundColor: 'transparent',
		marginVertical: 5
	}
	,
	reservationContainer: {
		alignSelf: 'stretch',
		display: 'flex',
		marginHorizontal: 10,
		marginVertical: 5,
		padding: 10,
		borderRadius: 5,
		backgroundColor: 'white',
		shadowColor: 'black',
		shadowOpacity: 0.25,
		shadowRadius: 2,
		elevation: 5,
	}
});
