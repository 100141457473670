const tintColorLight = '#2f95dc';
const tintColorDark = '#fff';

const primary = '#00d1b2';

const info = 'hsl(204, 86%, 53%)';
const infolight = 'hsl(204, 86%, 83%)';
const infodark = 'hsl(204, 86%, 33%)';

const success = 'hsl(141, 53%, 53%)';
const successdark = 'hsl(141, 53%, 33%)';

const danger = 'hsl(348, 100%, 61%)';
const dangerlight = 'hsl(348, 100%, 81%)';
const dangerdark = 'hsl(348, 100%, 31%)';

export default {
	primary: primary,
	
	info: info,
	infolight: infolight,
	infodark: infodark,

	success: success,
	successdark: successdark,

	danger: danger,
	dangerlight: dangerlight,
	dangerdark: dangerdark,

	light: {
		text: '#000',
		background: '#fff',
		tint: tintColorLight,
		tabIconDefault: '#ccc',
		tabIconSelected: tintColorLight,
	},
	dark: {
		text: '#fff',
		background: '#000',
		tint: tintColorDark,
		tabIconDefault: '#ccc',
		tabIconSelected: tintColorDark,
	},
};
