import React, { useState, useRef } from "react";
import { StyleSheet, Button, TextInput, Image, ActivityIndicator } from 'react-native';
import { Text, View } from '../components/Themed';
import Colors from "../constants/Colors";
import netErrMsg from "../constants/NetworkErrorMsg";

import useAuthNotifContext from "../hooks/useAuthNotifContext";

export default function AuthenticationScreen() {
	const { authenticate } = useAuthNotifContext();
	const [identifier, setIdentifier] = useState("");
	const [password, setPassword] = useState("");
	const [authenticating, setAuthenticating] = useState(false);
	const [error, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const passwordFieldRef = useRef<TextInput>(null);

	const handleSubmit = () => {
		if (identifier === "" || password === "") {
			setError(true);
			setErrorMessage("Veuillez saisir votre identifiant et votre mot de passe.");
			return;
		}

		setAuthenticating(true);
		authenticate(identifier, password)
			.then(res => {
				if (res === undefined) {
					setError(true);
					setErrorMessage(netErrMsg);
					setPassword("");
				}
				else if (res === false) {
					setError(true);
					setErrorMessage("Identifiant ou mot de passe incorrect.");
					setPassword("");
				}

				setAuthenticating(false);
			})
	}

	return (
		<View style={styles.container}>
			{ authenticating && <ActivityIndicator size="large" color={Colors.info} style={styles.activity} /> }
			<Image source={require("../assets/images/icon.png")} style={styles.image} />
			<Text style={styles.title}>Connexion</Text>
			<TextInput
				style={[styles.input, { borderColor: error ? Colors.danger : 'black' }]}
				value={identifier}
				onChangeText={setIdentifier}
				onSubmitEditing={() => passwordFieldRef.current?.focus()}
				placeholder="identifiant"
				autoFocus
				textAlign="center"
				editable={!authenticating}
			/>
			<TextInput ref={passwordFieldRef}
				style={[styles.input, { borderColor: error ? Colors.danger : 'black' }]}
				value={password}
				onChangeText={setPassword}
				onSubmitEditing={handleSubmit}
				placeholder="mot de passe"
				textAlign="center"
				secureTextEntry
				editable={!authenticating}
			/>
			<Text style={styles.errorMsg}>{errorMessage}</Text>
			<Button title="Valider" onPress={handleSubmit} disabled={authenticating} />
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: Colors.primary,
		alignItems: 'center',
		justifyContent: 'center',
		padding: 20,
	},
	title: {
		fontSize: 20,
		fontWeight: 'bold',
		// color: 'white',
	},
	input: {
		height: 40,
		width: '100%',
		margin: 12,
		borderWidth: 1,
		borderRadius: 5,
		padding: 10,
		backgroundColor: 'white',
	},
	errorMsg: {
		marginTop: -5,
		marginBottom: 5,
		textAlign: 'center',
		color: Colors.danger,
		fontWeight: 'bold',
	},
	activity: {
		borderRadius: 100,
		backgroundColor: "white",
		shadowColor: "black",
		shadowOpacity: 0.25,
		shadowRadius: 5,
		elevation: 5,
	},
	image: {
		width: 60,
		height: 60,
		marginBottom: 10,
	}
});