import React, { useState, useEffect, useRef } from 'react';
import { StyleSheet, ScrollView, RefreshControl, Platform } from 'react-native';
import { ReservationsProps } from '../types';
import { Subscription } from "@unimodules/core";
import { addNotificationReceivedListener } from 'expo-notifications';

import { API_URL } from '../constants/Urls';
import { useCallback } from 'react';
import RefreshButton from '../components/RefreshButton';
import CalendarPicker from '../components/CalendarPicker';
import useAuthNotifContext from '../hooks/useAuthNotifContext';
import { Text } from '../components/Themed';
import Colors from '../constants/Colors';
import netErrMsg from "../constants/NetworkErrorMsg";

export default function ReservationsScreen({ navigation }: ReservationsProps) {
	const { authToken, onNotification } = useAuthNotifContext();
	const [today] = useState(new Date());
	const [year, setYear] = useState(today.getFullYear());
	const [month, setMonth] = useState(today.getMonth());
	const [nbReservations, setNbReservations] = useState<{ [key: string]: number }>({});
	const [refreshing, setRefreshing] = useState(false);
	const [errorMsg, setErrorMsg] = useState("");

	const notificationListener = useRef<Subscription>();

	const fetchReservations = useCallback(() => {
		setRefreshing(true);
		setErrorMsg("");
		setNbReservations({});
		fetch(API_URL + `/reservations?
			date_gte=${new Date(Date.UTC(year, month, 1)).toISOString()}&
			date_lte=${new Date(Date.UTC(year, month + 1, 0)).toISOString()}&
			_sort=date:asc`,
			{
				method: 'GET',
				headers: {
					Authorization: `bearer ${authToken}`
				}
			}
		)
		.then(res => {
			if (res && res.ok)
				return res.json();
			else
				throw new Error(`${res.status}`);
		})
		.then(data => {
			const res: { [key: string]: number } = {};
			for (const d of data) {
				if (d.date in res) {
					res[d.date]++;
				}
				else {
					res[d.date] = 1;
				}
			}
			setNbReservations(res);
			setRefreshing(false);
		})
		.catch(error => {
			console.error('Error fetching reservations:', error);
			setErrorMsg(netErrMsg);
			setRefreshing(false);
		});
	}, [year, month])

	useEffect(() => {
		fetchReservations();
	}, [year, month])

	useEffect(() => {
		// This listener is fired whenever a notification is received while the app is foregrounded
		notificationListener.current = addNotificationReceivedListener(notification => {
			if (notification.request.content.data.type === 'reservation') {
				fetchReservations();
			}
		});

		return () => {
			if (notificationListener.current)
				notificationListener.current.remove();
		}
	}, [])

	useEffect(() => {
		onNotification(() => {
			return () => fetchReservations();
		})
	}, [onNotification])

	return (
		<ScrollView style={styles.container}
			refreshControl={
				<RefreshControl
					refreshing={refreshing}
					onRefresh={fetchReservations}
				/>
			}
		>
			{
				Platform.OS === 'web' ? <RefreshButton onPress={fetchReservations} /> : null
			}
			<CalendarPicker nbNotifications={nbReservations}
				onDatePicked={date => navigation.navigate(
					"ReservationsOfTheDay",
					{
						date: date,
					}
				)}
				onMonthChange={date => {
					setMonth(date.month);
					setYear(date.year);
				}}
			/>
			<Text style={{padding: 10, textAlign: 'center', color: Colors.danger, fontWeight: 'bold'}}>{errorMsg}</Text>
		</ScrollView>
	);
}

const styles = StyleSheet.create({
	container: {
		padding: 10,
		flex: 1,
		backgroundColor: 'white'
	},
});
