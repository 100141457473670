import { StackScreenProps } from '@react-navigation/stack';
import * as React from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';

import { RootStackParamList } from '../types';

export default function NotFoundScreen({
	navigation,
}: StackScreenProps<RootStackParamList, 'NotFound'>) {
	return (
		<View style={styles.container}>
			<Text style={styles.title}>Cette page n'existe pas</Text>
			<TouchableOpacity onPress={() => navigation.replace('Auth')} style={styles.link}>
				<Text style={styles.linkText}>Retourner à la page principale</Text>
			</TouchableOpacity>
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: '#fff',
		alignItems: 'center',
		justifyContent: 'center',
		padding: 20,
	},
	title: {
		fontSize: 20,
		fontWeight: 'bold',
	},
	link: {
		marginTop: 15,
		paddingVertical: 15,
	},
	linkText: {
		fontSize: 14,
		color: '#2e78b7',
	},
});
