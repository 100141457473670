import React from "react";
import { Pressable, StyleSheet } from 'react-native';

import { FontAwesome5 } from "@expo/vector-icons";
import Colors from "../constants/Colors";


export default function RefreshButton({ disabled=false, onPress=() => {} }) {

	return (
		<Pressable style={({ pressed }) => [styles.RefreshButton, pressed && styles.RefreshButtonPressed]} onPress={onPress} disabled={disabled}>
			<FontAwesome5 name="redo-alt" color={disabled ? Colors.infolight : Colors.info} size={22} style={{ marginLeft: 2 }} />
		</Pressable>
	);
}

const styles = StyleSheet.create({
	RefreshButton: {
		display: 'flex',
		alignItems: 'center', justifyContent: 'center',
		width: 30, height: 30,
		borderRadius: 15,
		margin: 5
	},
	RefreshButtonPressed: {
		backgroundColor: '#eed'
	}
});