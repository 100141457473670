/**
 * Learn more about createBottomTabNavigator:
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */

import { FontAwesome5 } from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';

import Colors from '../constants/Colors';
import ReservationsScreen from '../screens/ReservationsScreen';
import ReservationsOfTheDayScreen from '../screens/ReservationsOfTheDaySreen';
import OrdersScreen from '../screens/OrdersScreen';
import SettingsScreen from '../screens/SettingsScreen';
import { BottomTabParamList, ReservationsParamList, OrdersParamList, SettingsParamList } from '../types';
import useAuthNotifContext from '../hooks/useAuthNotifContext';

const BottomTab = createBottomTabNavigator<BottomTabParamList>();

export default function BottomTabNavigator() {

	return (
		<BottomTab.Navigator
			initialRouteName="Reservations"
			tabBarOptions={{ activeTintColor: Colors.info }}>
			<BottomTab.Screen
				name="Reservations"
				component={ReservationsNavigator}
				options={{
					tabBarIcon: ({ color }) => <TabBarIcon name="calendar-alt" color={color} />,
				}}
			/>
			<BottomTab.Screen
				name="Orders"
				component={OrdersNavigator}
				options={{
					tabBarIcon: ({ color }) => <TabBarIcon name="shopping-bag" color={color} />,
				}}
			/>
			{/* <BottomTab.Screen
				name="Settings"
				component={ParameterNavigator}
				options={{
					tabBarIcon: ({ color }) => <TabBarIcon name="cog" color={color} />,
				}}
			/> */}
		</BottomTab.Navigator>
	);
}

// You can explore the built-in icon families and icons on the web at:
// https://icons.expo.fyi/
function TabBarIcon(props: { name: React.ComponentProps<typeof FontAwesome5>['name']; color: string | undefined }) {
	return <FontAwesome5 size={30} style={{ marginBottom: -3 }} {...props} />;
}

// Each tab has its own navigation stack, you can read more about this pattern here:
// https://reactnavigation.org/docs/tab-based-navigation#a-stack-navigator-for-each-tab
const ReservationsStack = createStackNavigator<ReservationsParamList>();

function ReservationsNavigator() {
	const { DisconnectButton } = useAuthNotifContext();

	return (
		<ReservationsStack.Navigator>
			<ReservationsStack.Screen
				name="ReservationsScreen"
				component={ReservationsScreen}
				options={{ 
					headerTitle: 'Réservations',
					headerRight: () => <DisconnectButton />
				}}
			/>
			<ReservationsStack.Screen
				name="ReservationsOfTheDay"
				component={ReservationsOfTheDayScreen}
				options={({ route }) => ({ 
					headerTitle: 'Réservations du ' + route.params.date.split('-').reduce((a, b) => b + '/' + a) 
				})}
			/>
		</ReservationsStack.Navigator>
	);
}

const OrdersStack = createStackNavigator<OrdersParamList>();

function OrdersNavigator() {
	const { DisconnectButton } = useAuthNotifContext();

	return (
		<OrdersStack.Navigator>
			<OrdersStack.Screen
				name="OrdersScreen"
				component={OrdersScreen}
				options={{ 
					headerTitle: 'Commandes',
					headerRight: () => <DisconnectButton /> 
				}}
			/>
		</OrdersStack.Navigator>
	);
}

const SettingsStack = createStackNavigator<SettingsParamList>();

function ParameterNavigator() {
	return (
		<SettingsStack.Navigator>
			<SettingsStack.Screen
				name="SettingsScreen"
				component={SettingsScreen}
				options={{ headerTitle: 'Réglages' }}
			/>
		</SettingsStack.Navigator>
	);
}
