/**
 * If you are not familiar with React Navigation, check out the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { NavigationContainer, DefaultTheme, DarkTheme } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import { ColorSchemeName, Text } from 'react-native';

import AuthenticationScreen from '../screens/AuthenticationScreen';
import NotFoundScreen from '../screens/NotFoundScreen';
import { RootStackParamList } from '../types';
import BottomTabNavigator from './BottomTabNavigator';
import LinkingConfiguration from './LinkingConfiguration';

import useAuthNotifContext, { AuthNotifContextProvider } from '../hooks/useAuthNotifContext';

export default function Navigation({ colorScheme }: { colorScheme: ColorSchemeName }) {
	return (
		<AuthNotifContextProvider>
			<NavigationContainer
				linking={LinkingConfiguration}
				theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}>
				<RootNavigator />
			</NavigationContainer>
		</AuthNotifContextProvider>
	);
}

// A root stack navigator is often used for displaying modals on top of all other content
// Read more here: https://reactnavigation.org/docs/modal
const Stack = createStackNavigator<RootStackParamList>();

function RootNavigator() {
	const { authToken } = useAuthNotifContext();

	return (
		<Stack.Navigator screenOptions={{ headerShown: false }}>
			{
				authToken ?
					<Stack.Screen name="Root" component={BottomTabNavigator} />
				:
					<Stack.Screen name="Auth" component={AuthenticationScreen} />
			}
			<Stack.Screen name="NotFound" component={NotFoundScreen} options={{ title: 'Oops!' }} />
		</Stack.Navigator>
	);
}
