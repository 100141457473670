import React, { useState, useContext, useEffect } from "react";
import { Pressable, StyleSheet } from 'react-native';

import { FontAwesome5 } from "@expo/vector-icons";
import Constants from 'expo-constants';
import { API_URL } from "../constants/Urls";
import Colors from "../constants/Colors";

import firebase from "firebase/app";
import "firebase/firebase-messaging";

const VAPID_PUBLIC_KEY = "BHChnlzizSt_FaUdMD_aIzwV8l6tYMSDhc2-AK60TEk0L0-vCTMo3EK9t4CFvEw5V4Ry_7wHsnT3o2ujNydX7QQ"

const firebaseConfig = {
	apiKey: "AIzaSyD93Gx7UiZBNz26nMdFZ7dOL4Cg3LSRFrg",
	authDomain: "bibimbap-27548.firebaseapp.com",
	projectId: "bibimbap-27548",
	storageBucket: "bibimbap-27548.appspot.com",
	messagingSenderId: "81698390934",
	appId: "1:81698390934:web:88cd736deaa78d632e13b7",
	measurementId: "G-PEQ4MNJVGP"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const messaging = firebase.messaging(firebaseApp);

async function registerForPushNotificationsAsync() {
	let token;

	if (Constants.isDevice) {
		const existingStatus = Notification.permission;
		let finalStatus = existingStatus;
		if (existingStatus !== 'granted') {
			finalStatus = await Notification.requestPermission();
		}
		if (finalStatus !== 'granted') {
			alert('Failed to get notification permission !');
			return;
		}

		const swRegistration = await navigator.serviceWorker.register("firebase-messaging-sw.js")

		token = await messaging.getToken({ 
			vapidKey: VAPID_PUBLIC_KEY,
			serviceWorkerRegistration: swRegistration
		})
	}
	else {
		alert('Must use physical device for Push Notifications');
	}

	return token;
};

async function subscribeForPushNotificationsAsync(authToken: string) {
	if (!authToken) {
		console.error("Error: no authentication token");
		return false;
	}

	let pushToken = await registerForPushNotificationsAsync();

	const res = await fetch(API_URL + "/firebase-push-tokens", {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `bearer ${authToken}`
		},
		body: JSON.stringify({ token: pushToken })
	})

	if (!res.ok) {
		console.error(await res.text());
	}

	return res.ok;
}

export type AuthNotifContent = {
	authToken: string | undefined,
	authenticate: (identifier: string, password: string) => Promise<boolean | undefined>,
	disconnect: () => void,
	onNotification: (payload: any) => void,
	DisconnectButton: () => JSX.Element
}

export const AuthNotifContext = React.createContext<AuthNotifContent>({
	authToken: undefined,
	authenticate: async (identifier: string, password: string) => { return undefined },
	disconnect: () => { },
	onNotification: () => { },
	DisconnectButton: () => <SignOutButton disabled />
});

export function AuthNotifContextProvider({ children }: { children: any }) {
	const [authToken, setAuthToken] = useState<string>();
	const [notificationCallback, onNotification] = useState<AuthNotifContent["onNotification"]>(() => {});

	const authenticate = async (identifier: string, password: string) => {

		let res;
		try {

			res = await fetch(API_URL + "/auth/local", {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					identifier: identifier,
					password: password
				})
			})
		}
		catch (e) {
			console.error(e);
		}

		if (res === undefined)
			return undefined;

		if (!res.ok) {
			return false
		}

		const { jwt } = await res.json();
		setAuthToken(jwt);
		subscribeForPushNotificationsAsync(jwt);
		return true;
	}

	const disconnect = () => {
		setAuthToken(undefined);
	}

	const DisconnectButton = () => {
		return (
			<SignOutButton disabled={authToken === undefined}
				onPress={disconnect}
			/>
		);
	}

	useEffect(() => {
		messaging.onMessage((payload) => {
			console.log('Message received. ', payload);
			
			notificationCallback(payload);
		});
	}, [notificationCallback])

	return (
		<AuthNotifContext.Provider value={{ authToken, authenticate, disconnect, onNotification, DisconnectButton }}>
			{children}
		</AuthNotifContext.Provider>
	);
}

function SignOutButton({ disabled, onPress }: { disabled: boolean, onPress?: () => void }) {
	return (
		<Pressable style={({ pressed }) => [styles.signOutButton, pressed && styles.signOutButtonPressed]} onPress={onPress} disabled={disabled}>
			<FontAwesome5 name="sign-out-alt" color={disabled ? Colors.dangerlight : Colors.danger} size={22} style={{ marginLeft: 2 }} />
		</Pressable>
	)
}

const styles = StyleSheet.create({
	signOutButton: {
		display: 'flex',
		alignItems: 'center', justifyContent: 'center',
		width: 30, height: 30,
		borderRadius: 15,
		margin: 5
	},
	signOutButtonPressed: {
		backgroundColor: '#dee'
	}
});

export default function useAuthNotifContext() { return useContext(AuthNotifContext); }